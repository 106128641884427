<template>
  <div id="contact-section" class="bg-gray-800 p-5 lg:p-10">
    <div class="md:p-5 lg:p-10 md:max-w-6xl mx-auto text-white">
      <h2 class="font-catamaran font-black text-4xl xl:text-7xl text-green-300">GET IN TOUCH!</h2>
      <p class="py-4 lg:py-10 lg:text-lg">Library looking for composers? Need production music for your projects?<br>Let's talk!</p>
      <a class="text-green-300 hover:text-white" href="mailto:electricsaunanyc@gmail.com">electricsaunanyc@gmail.com</a>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>