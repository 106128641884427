<template>
  <div id="music-section" class="bg-gray-800">
    <div class="container grid grid cols-1 md:grid-cols-2 md:gap-5 mx-auto md:p-5 lg:p-10">
      <div class="text-white p-5 lg:p-10">
        <h2 class="font-catamaran font-black text-4xl xl:text-7xl text-green-300">ROCK</h2>
        <p class="py-4 lg:py-10 lg:text-lg">Confident rock aiming for the stadium. Fuzzy guitar riffs and big drums bring excitement and brash, sing-along energy.</p>
        <div>
          <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1362870358&color=%231c747c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/electricsaunaproductions" title="Electric Sauna Productions" target="_blank" style="color: #cccccc; text-decoration: none;">Electric Sauna Productions</a> · <a href="https://soundcloud.com/electricsaunaproductions/sets/rock-swagger" title="Rock: Swagger" target="_blank" style="color: #cccccc; text-decoration: none;">Rock: Swagger</a></div>
        </div>
      </div>
      <div class="text-white p-5 lg:p-10">
        <h2 class="font-catamaran font-black text-4xl xl:text-7xl text-green-300">FOLK</h2>
        <p class="py-4 lg:py-10 lg:text-lg">A positive collection of upbeat, feel-good folk pop with driving acoustic guitars, mellotron, bass, piano, and percussion.</p>
        <div>
          <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1387235947&color=%231c747c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/electricsaunaproductions" title="Electric Sauna Productions" target="_blank" style="color: #cccccc; text-decoration: none;">Electric Sauna Productions</a> · <a href="https://soundcloud.com/electricsaunaproductions/sets/acoustic-folk-pop" title="Acoustic: Folk Pop" target="_blank" style="color: #cccccc; text-decoration: none;">Acoustic: Folk Pop</a></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>