<template>
  <footer>
    <div class="bg-black p-5 lg:p-10">
      <p class="text-grey-200">© Electric Sauna Productions 2022</p>
    </div>
  </footer>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>