<template>
  <div id="about-section" class="bg-hero bg-no-repeat bg-cover p-5 lg:p-10">
    <div class="p-5 lg:p-10 md:max-w-4xl mx-auto">
      <h2 class="font-catamaran font-black text-4xl xl:text-7xl text-green-300">ABOUT</h2>
      <!-- <img class="col-span-1" src="../assets/robin-rock-shop.jpg" alt=""> -->
      <p class="text-white py-4 lg:py-10 lg:text-lg">Electric Sauna Productions was founded in New York City by experienced musician, composer, and producer Robin Pahlman. In his career, Robin has served as sideman and bandleader in numerous bands and played shows all over he world: from small DIY venues in Europe to New York City's Carnegie Hall. Through Electric Sauna Productions, Robin composes production music for music libraries in various styles of rock, pop, folk, and acoustic music.</p>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>